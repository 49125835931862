import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { Breadcrumb } from "../components";

const AnnouncementDetail = props => {
  const { data } = props;

  const breadcrumbData = useMemo(() => {
    return [
      {
        id: 1,
        name: "Home",
        link: "/",
      },
      {
        id: 2,
        name: "Announcements",
        link: "/announcements",
      },
      {
        id: 3,
        name: data?.title,
      },
    ];
  }, [data]);

  return (
    <div className="detail">
      <div className="container">
        <Breadcrumb data={breadcrumbData} />
        <h3 className="detail__title mb-0">{data?.title}</h3>
        <span className="detail__date">{data?.type}</span>
        <div className="detail__img">
          <img src={data?.image_url} alt={data?.title} />
        </div>
        <p className="detail__desc mt-4">
          <ReactMarkdown
            children={data?.full_description}
            remarkPlugins={[remarkGfm]}
          />
        </p>
      </div>
    </div>
  );
};

export default AnnouncementDetail;
