import React from "react";

import { BACKEND_BASE_URL } from "../../constant/constants";

import Layout from "../../components/layout";
import { Seo } from "../../components";
import AnnouncementDetail from "../../container/announcement-detail";

const AnnouncementDetailPage = arg => {
  return (
    <Layout location={arg.location}>
      <Seo title="Announcement Detail" />
      <AnnouncementDetail data={arg.serverData.announcement_info} />
    </Layout>
  );
};

export async function getServerData(context) {
  try {
    const announcement_info_res = await fetch(
      BACKEND_BASE_URL + "announcements/" + context.params.uuid
    );

    if (!announcement_info_res.ok) {
      throw new Error(`Response failed`);
    }
    return {
      props: {
        announcement_info: await announcement_info_res.json(),
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default AnnouncementDetailPage;
